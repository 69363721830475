//File is ignored by Git
export const magicImport = (refComponent: string) => {
  return () =>
    import(
      /* Payback: Insert asb_modules\/payback\/ for asb_modules\/payback\/ */
      /* webpackInclude: /((core\/|components\/|asb_modules\/payback\/)(?!.*(\.test\.tsx|setupTests\.ts|\.stories\.(js|mdx)|\.snap).*))/ */
      `../../${refComponent}`
    ).catch((e) => {
      console.error(e);
    });
};
